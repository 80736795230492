// Here you can add other styles
.c-app {
    background-color: white !important;
    min-height: 80vh !important;
}

.c-footer.public {
    background-color: #C1343E !important;
    color: white !important;
}

.c-sidebar-brand {
    background-color: white !important;
    border: 1px solid #C1343E;
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    background-color: #921827 !important;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle, .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link {
    background-color: #921827 !important;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle:hover, .c-sidebar .c-sidebar-nav-link:hover {
    background-color: #921827 !important;
}

.show > .btn-outline-primary.dropdown-toggle {
    background-color: #921827 !important;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #921827 !important;
}

.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
    background-color: #921827 !important;
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    background-color: #921827 !important;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover, .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover {
    background-color: #921827 !important;
}

.c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle {
    background-color: #921827 !important;
}

.c-sidebar.c-sidebar-light.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
    background-color: #921827 !important;
}

.c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle {
    background: #921827 !important;
}

.dropdown-item:hover, .dropdown-item:focus {
    background: #921827 !important;
}

.dropdown-item.active, .dropdown-item:active {
    background: #921827 !important;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
    background: #921827 !important;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items {
    background: #EE3638 !important;
}

.c-footer.public a {
    color: white;
}

.c-sidebar {
    background-color: #C1343E;
}

.card.public {
    border-color: #C1343E !important;
}

.company-header {
    padding: 4px;
}

.company-header-name a {
    color: #C1343E;
}

.public-full-logo {
    height: 60px;
}

.full-logo {
    height: 48px;
    margin-left: -30px;
    margin-top: 7px;
    padding-bottom: 5px;
}

.public-half-logo {
    height: 25px;
    padding-bottom: 5px;
}

.half-logo {
    height: 48px;
    padding-bottom: 5px;
}

.c-header-toggler {
    float: left;
    margin-top: 15px;
}
.c-header.public {
    display: flow-root;
    background-color: #C1343E !important;
}
.c-header-nav-left {
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 56px;
    padding: 0;
        padding-right: 0px;
        padding-left: 0px;
    margin-bottom: 0;
    list-style: none;
}
.c-header-nav {
    float: right;
}

.card-header {
    border-bottom: none !important;
}

.card-body {
    padding-top: 0;
}

nav.rdt_Pagination {
    justify-content: flex-start !important;
}
//Print page
page {
    background: white;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
}
page[size="A4"] {
    width: 21cm;
    height: 29.7cm;
}
table.printable-no-border {
	border-collapse: collapse;
    width: 700px;
    margin: 30px;
}
table.printable-no-border td {
    padding: 0;
    vertical-align: text-top;
}
table.printable {
	border-collapse: collapse;
    width: 700px;
    margin: 30px;
}
table.printable td {
    padding: 0;
    vertical-align: text-top;
}
table.printable thead th {
    border-bottom: 2px solid;
        border-bottom-color: currentcolor;
    border-bottom-color: #d8dbe0;
}
table.printable th, table.printable td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid;
    border-top-color: currentcolor;
    border-top-color: #d8dbe0;
}
.bordered {
	border: 1px solid black;
	padding: 5px 5px 5px 10px;
}
@media print {
    body, page {
        margin: 0;
        box-shadow: 0;
    }
}

.container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.container > p {
  font-size: 1rem;
}

.container > em {
  font-size: .8rem;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}

.form-check-label {
    margin-bottom: -9px;
}

.is-invalid {
    border-color: #e55353;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e55353' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e55353' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    font-weight: bold;
    color: #e55353;
}

.mobile-shown {
    display: none;
}

.quantity-trx-adjustment {
    padding-left: 5px;
    padding-right: 5px;
}

#help-container {
    position: fixed;
    z-index: 999999999;
    text-align: center;
    left: 10px;
    bottom: 15px;
}
  
#help-container-header {
    padding: 0px;
    cursor: move;
    z-index: 10;
    color: #fff;
}

#help-container-header button {
    color: white;
}

#scan-container {
    position: fixed;
    z-index: 9;
    text-align: center;
    right: 15px;
    bottom: 85px;
}
  
#scan-container-header {
    padding: 0px;
    cursor: move;
    z-index: 10;
    color: #fff;
}

.btn-scan {
    color: #fff;
    border: 2px solid #3c4b64 !important;
}

.list-header {
    width: 85%;
}

.filter-buttons {
    float: right;
    margin-top: -30px;
}

.c-footer {
    display: inline-block;
}

.m-show {
    display: none;
}

.float-menu {
    float: right;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 200000;
}

.float-menu > a {
    color: white;
    background-color: #3c4b64;
}

.float-menu > a:hover {
    color: white;
}

.float-menu > li > svg {
    margin-left: 2px !important;
}

.dGvMhs {
    height: 40px !important;
    width: 40px !important;
}

.sweet-alert div {
    white-space: pre-wrap;
}

.form-control.no-padding {
    height: auto !important;
    padding: 0 !important;
}

.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
}

.react-tagsinput-tag {
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid #3c4b64;
    color: #3c4b64;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
}

.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: " x";
}

@media (min-width:375px) and (orientation:portrait) {
    div.row-line:nth-of-type(2n+1) {
        background-color: #eaeaea;
        border-radius: 3px;
    }

    .mobile-hidden {
        display: none;
    }
 
    .mobile-shown {
        display: block;
    }

    .quantity-trx-adjustment {
        padding-left: 15px;
        padding-right: 15px;
    }

    .m-show {
        display: block;
    }

    .m-hide {
        display: none;
    }

    .card-footer {
        display: flex !important;
    }
}

@media (min-width:360px) and (orientation:portrait) {
    div.row-line:nth-of-type(2n+1) {
        background-color: #eaeaea;
        border-radius: 3px;
    }

    .mobile-hidden {
        display: none;
    }
 
    .mobile-shown {
        display: block;
    }

    .company-header {
        padding: 0px;
    }

    .list-header {
        width: 75%;
    }

    .filter-buttons {
        float: right;
        margin-top: -55px;
    }

    .m-show {
        display: block;
    }

    .m-hide {
        display: none;
    }

    .card-footer {
        display: flex !important;
    }
}